<template lang='pug'>
.wrapper-builder
  navBarBuilder(:actualRoute="actualRoute")
  .height-view
    router-view 
</template>
<script>
import navBarBuilder from "@/components/structure/navBuilder/navBar-builder.vue";
export default {
  components: {
    navBarBuilder,
  },
  data: () => ({
    actualRoute: "builder/select-template",
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.actualRoute = to.name;
      },
    },
  },
  computed: {},
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.wrapper-builder {
  height: calc(100vh - 57px);
  max-height: calc(100vh - 57px);
  margin-top: 57px;
  @apply flex flex-col;
  // @apply flex flex-col;
}
.height-view {
  width: 100%;
  max-width: 100vw;
  @apply bg-blue-50 relative flex flex-col flex-grow;
}
</style>