<template lang="pug">
router-view(v-if="loaded")
</template>

<script>
import { UsersRepository } from "@/repository/users.repository";
export default {
  name: "builder-wrapper-empty",
  props: {
    curriculum_id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loaded: false,
  }),
  async created() {
    const curriculumEntity = await UsersRepository.getCurriculum(
      this.curriculum_id
    );
    await this.$store.dispatch("DataCvBuilderStore/loadCV", {
      curriculum_id: this.curriculum_id,
      cvData: curriculumEntity.curriculum_data,
    });
    if (curriculumEntity.snapshot_base64) {
      await this.$store.commit(
        "DataCvViewerStore/setSnapShotBase64",
        curriculumEntity.snapshot_base64
      );
    }
    this.loaded = true;
  },
  async beforeUnmount() {
    await this.$store.dispatch("DataCvBuilderStore/deleteStoreCurriculum");
  },
};
</script>

<style lang="scss" scoped></style>
