<template lang="pug">
.nav-steps
  navPhone(:tab="selectedTab" :title="titleTab" v-if="isMobile")
  navDesktop(:tab="selectedTab"  v-else)
</template>

<script>
import navPhone from './nav-phone.vue'
import navDesktop from './nav-desktop.vue'

export default {
  name: "navBar-builder",
  components: {
    navPhone,
    navDesktop
  },
  props: {
    actualRoute: {
      type: String,
      default: "builder/select-template",
    },
  },
  provide() {
    return {
      logout: () => this.$store.dispatch("UserStore/logout"),
    };
  },
  data: () => ({
    isMobile: false,
    mql: window.matchMedia('(max-width: 600px)')
  }),
  created() {
    this.mql.addEventListener('change', this.setComponentIsMobile);
    this.setComponentIsMobile(this.mql)
  },
  beforeUnmount() {
    this.mql.removeEventListener("change", this.setComponentIsMobile);
  },
  methods: {
    setComponentIsMobile(event) {
      this.isMobile = event.matches;
    }
  },
  computed: {
    selectedTab() {
      if (
        this.actualRoute == "builder/select-template" ||
        this.actualRoute == "builder/select-template/with-id"
      ) {
        return "templates";
      }
      if (
        this.actualRoute == "builder/creator" ||
        this.actualRoute == "builder/creator/with-id"
      ) {
        return "creator";
      }
      if (
        this.actualRoute == "builder/payment" ||
        this.actualRoute == "builder/payment/with-id"
      ) {
        return "payment";
      }
      return "select-template";
    },
    titleTab() {
      return this.titlesTab[this.selectedTab]
    },
    titlesTab() {
      return {
        "templates": this.$t('navBuilder.phone_templates'),
        "creator": this.$t('navBuilder.phone_creator'),
        "payment": this.$t('navBuilder.phone_payment'),
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.nav-steps {
  @apply fixed top-0 bg-white z-10 shadow-sm w-full;
}
</style>
