<template lang="pug">
.nav-desktop
  .box-logo
    baseLogoWeb
  .box-steps
    .box-step.justify-end(:class="{ active: tab == 'templates' }")
      .box-number-position 1
      .box-name-position {{ $t('navBuilder.desktop_templates') }}
    .box-step.justify-center(:class="{ active: tab == 'creator' }")
      .box-number-position 2
      .box-name-position {{ $t('navBuilder.desktop_creator') }}
    .box-step.justify-start(:class="{ active: tab == 'payment' }")
      .box-number-position 3
      .box-name-position {{ $t('navBuilder.desktop_payment') }}

  .box-logo-invisible(v-if="!$store.getters['UserStore/getIsLogged']")
    baseLogoWeb
  XyzTransition(xyz="fade")
    ul.flex.items-center.justify-end(v-if="$store.getters['UserStore/getIsLogged']")
      navBarLink
        router-link(:to="{ name: 'my-cvs' }") {{ $t('navLinks.my_cvs') }}
</template>

<script>
import navBarLink from "../navbar/navbar-link.vue";
import navBarLinkDownloads from "../navbar/link-downloads.vue";

export default {
  name: 'nav-desktop',
  components: {
    navBarLink,
    navBarLinkDownloads,
  },
  props: {
    tab: {
      type: String,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-desktop {
  // @apply py-3 px-4 flex items-center justify-between gap-2;
  @apply py-3 px-4 grid grid-cols-3 items-center justify-between gap-2;
}
.box-logo {
  @apply flex-shrink-0 invisible sm:visible;
}
.box-logo-invisible {
  @apply hidden flex-shrink flex-grow-0 md:invisible md:block;
}

.box-steps {
  @apply flex items-center gap-4 justify-center;
}

.box-step {
  @apply flex items-center;

  .box-number-position {
    @apply text-sm font-bold bg-gray-200 rounded-full h-6 w-6 flex justify-center items-center flex-shrink-0;
  }

  .box-name-position {
    @apply text-xs font-normal pl-2 md:text-sm;
  }

  &.active {
    .box-name-position {
      @apply font-bold;
    }
    .box-number-position {
      @apply bg-indigo-600 text-white;
    }
  }
}
</style>
