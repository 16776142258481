<template lang="pug">
.box-circle
  .flex.items-center.justify-center
    CircleProgress(
      :size="40"
      fill-color="rgba(96, 165, 250,1)"
      empty-color="rgba(96, 165, 250,0.2)"
      :percent="percent"
      :is-bg-shadow="false"
      :border-width="4"
      :border-bg-width="4"
    )
    .content-progress {{ step }}/3
</template>

<script>
import CircleProgress from "vue3-circle-progress";

const percents = {
  1: 33,
  2: 66,
  3: 100
}

export default {
  name: 'circle-steps',
  components: {
    CircleProgress
  },
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  computed: {
    percent() {
      return percents[this.step]
    }
  }
}
</script>

<style lang="scss" scoped>
.box-circle {
  @apply relative flex items-center justify-start;
  .content-progress {
    @apply absolute text-xs font-bold text-blue-800;
  }
}
</style>
